/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const searchStorageKey = 'vosbor-search';
export const chartFiltersStorageKey = 'vosbor-chart-filters';
export const timeZoneStorageKey = 'vosbor-timezone';
export const chartStateStorageKey = 'vosbor-chart-state';
export const mobilePortraitChartStateStorageKey = 'vosbor-mobile-portrait-chart-state';
export const mobileLandscapeChartStateStorageKey = 'vosbor-mobile-landscape-chart-state';
export const chartResolutionKey = 'vosbor-chart-resolution';
export const mobilePortaitChartResolutionKey = 'vosbor-mobile-portrait-chart-resolution';
export const mobileLandscapeChartResolutionKey = 'vosbor-mobile-landscape-chart-resolution';

export const CurrentSettingsVersion = '12'; // bump whenever we apply breaking change to any data we store in local storage
export const CurrentSettingsVersionKey = 'vosbor-settings-version';

export const contactsSortStorageKey = 'vosbor-contacts-sort';
export const allUsersSortStorageKey = 'vosbor-all-users-sort';

export const teamsDashboardDefaultTeamId = 'vosbor-teams-dashboard-team-id';

export const activeMarketKey = 'vosbor-active-market';
