/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { Table } from 'semantic-ui-react';
import { RowCell } from './Cell';

export const HoverCell = styled(RowCell)`
	.hover {
		display: none;
	}

	.content {
		display: block;
	}

	tr:hover & {
		padding: 0 !important;

		.hover {
			display: block;
		}

		.content {
			display: none;
		}
	}
`;

export const ClickableRow = styled(Table.Row)`
	& > td {
		cursor: pointer;
	}

	${props =>
		(!props.$highlight || props.$expired) &&
		css`
			&.highlight-row {
				--background: var(--neutral-850) !important;
				background: var(--background);
			}
		`}
`;

export const MyActivitiesRow = styled(ClickableRow)`
	height: 30px;
	margin-bottom: 2px;

	--background: var(--neutral-900);
	--hoverBackground: var(--neutral-850);

	.ui.selectable.table tbody &.inactive-row {
		--background: var(--neutral-1000);

		&:hover {
			--hoverBackground: var(--neutral-900) !important;
		}

		td.product-cell,
		td.name-visibility-cell span,
		td.principal-cell span,
		td.counterparty-cell span,
		td.column-volume-per-mt,
		td.price-color-buy,
		td.price-color-sell,
		td.runs-cell,
		td.quote-type-cell {
			color: var(--text-color-secondary) !important;
		}

		.otc-label {
			background: var(--neutral-300);
			color: var(--neutral-950);
		}
	}

	background: var(--background);

	&:hover {
		background: var(--hoverBackground) !important;
	}

	${({ $highlight, $expired }) =>
		$highlight &&
		!$expired &&
		css`
			background: var(--table_row_gradient);
		`};

	${({ $expired }) =>
		$expired &&
		css`
			td {
				color: inherit !important;
			}
		`};

	.expanded &.just-edited {
		background: linear-gradient(270deg, rgba(8, 19, 34, 0) 0%, rgba(55, 60, 217, 0.6) 100%);
		transition: background 0.2s ease-in-out;

		td {
			color: var(--text-color-default);

			&.product-cell {
				color: var(--purple-200);
			}
		}
	}

	.ui.selectable.table tbody &.edit-mode-row {
		&:hover {
			background: var(--neutral-850) !important;
		}
	}

	.ui.selectable.table tbody &.row-changed {
		&,
		&:hover {
			background: linear-gradient(270deg, var(--neutral-850) 0%, #122037 100%) !important;
		}
	}
`;
